<template>
    <div>
        <div class="flex flex-col flex-1 h-full pb-8" v-if="floorplan">
            <div class="flex justify-between bg-gray-100 odd:bg-white py-8 px-4 first:pt-0">
                <div class="w-1/3">
                    <file-manager
                        :main-directory="'cms'"
                        :library="getLibraryImageFilesByBaseDirectory('cms')"
                        :max-nb-of-files="10"
                        :max-upload-size="20"
                        :cropper-options="{aspectRatio: 16 / 9, cropBoxResizable: true, zoomOnWheel: false}"
                        v-model="floorplan.gallery.images"
                        :source= this.source
                    >
                        <template v-slot:hint="{ canAddFiles, maxNbOfFiles, maxUploadSize }">
                            <span class="form-hint" v-if="canAddFiles">
                                Select at least 1 image (up to {{ maxNbOfFiles }}).
                                The max upload file size is {{ maxUploadSize }}MB.
                            </span>
                        </template>
                    </file-manager>
                    <span v-if="hasError('floorplan.gallery.images')" class="form-error">{{ showError('floorplan.gallery.images') }}</span>
                </div>
                <div class="w-2/3 pl-4">
                    <div class="form-row">
                        <div class="form-col">
                            <label for="common_name">Public Name</label>
                            <text-input :disabled="this.source" id="common_name" v-model="floorplan.common_name" />
                            <span v-if="hasError('floorplan.common_name')" class="form-error">{{ showError('floorplan.common_name') }}</span>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-col">
                            <label for="unit_type">Unit type</label>
                            <text-input :disabled="this.source" id="unit_type" v-model="floorplan.unit_type " />
                            <span v-if="hasError('floorplan.unit_type')" class="form-error">{{ showError('floorplan.unit_type') }}</span>
                        </div>
                        <div class="form-col">
                            <label for="square_feet">Square feet</label>
                            <text-input :disabled="this.source" type="number" min="0" id="square_feet" v-model="floorplan.square_feet" />
                            <span v-if="hasError('floorplan.square_feet')" class="form-error">Must not be less than 0.</span>
                        </div>
                        <div class="form-col">
                            <label for="starting_price">Starting price</label>
                            <AmountInput 
                                v-if="!this.source"
                                v-model="floorplan.starting_price" 
                                id="starting_price"
                                prefix="$" 
                                amountClass="form-input"
                                
                            />
                            <span v-if="hasError('floorplan.starting_price')" class="form-error">{{ showError('floorplan.starting_price') }}</span>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-col">
                            <label for="bedrooms">Bedrooms</label>
                            <text-input :disabled="this.source" type="number" min="0" id="bedrooms" v-model="floorplan.bedrooms" />
                            <span v-if="hasError('floorplan.bedrooms')" class="form-error">Must not be less than 0.</span>
                        </div>
                        <div class="form-col">
                            <label for="bathrooms">Bathrooms</label>
                            <text-input :disabled="this.source" type="number" min="0"  step=".5" id="bathrooms" v-model="floorplan.bathrooms"  />
                            <span v-if="hasError('floorplan.bathrooms')" class="form-error">Must not be less than 0.</span>
                        </div>
                        <div class="form-col">
                            <label for="available_units">Available</label>
                            <text-input :disabled="this.source" type="number" min="0" id="available_units" v-model="floorplan.available_units" />
                            <span v-if="hasError('floorplan.bathrooms')" class="form-error">Must not be less than 0.</span>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-col">
                            <label for="virtual_tour_url">Virtual Tour Url</label>
                            <text-input :disabled="this.source" id="virtual_tour_url" v-model="floorplan.virtual_tour_url"/>
                            <span v-if="hasError('floorplan.virtual_tour_url')" class="form-error">{{ showError('floorplan.virtual_tour_url') }}</span>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-col">
                            <label for="available_units">Description</label>
                            <text-input :disabled="this.source" id="description" v-model="floorplan.description" multiline />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal-footer v-if="floorplan?.source == null" :primary="save"></modal-footer>
    </div>
</template>

<script>
    import EventBus from "@/utils/EventBus";
    import {mapActions, mapGetters} from "vuex";
    import ModalNavigation from "@/mixins/ModalNavigation";
    import ModalFooter from "@/components/ui/modals/ModalFooter";
    import FileManager from "@/components/ui/filemanager/FileManager";
    import TextInput from '@/components/ui/TextInput';
    import AmountInput from '@/components/ui/AmountInput';

    export default {
        mixins: [ ModalNavigation ],
        components: { ModalFooter, FileManager, TextInput, AmountInput },
        data: () => {
            return {
                website: {},
                floorplan: null,
                isProcessing: false,
                source: null
            }
        },
        computed: {
            ...mapGetters({
                getFloorplanByName: 'floorplans/getFloorplanByName',
                getLibraryImageFilesByBaseDirectory: 'files/getLibraryImageFilesByBaseDirectory',
                getThumbnailUrlByFileId: 'files/getThumbnailUrlByFileId',
                getFileIdByThumbnailUrl: 'files/getFileIdByThumbnailUrl',
                getWebsiteInfo: 'website_info/getWebsiteInfo',
                backModal: 'modals/getLastModal',
            }),
        },
        methods: {
            ...mapActions({
                loadFloorplansInStore: 'floorplans/loadFloorplans',
                notifySuccessBack: 'alerts/notifySuccessBack',
                notifyError: 'alerts/notifyError',
                removeActionFromStore: 'actions/removeAction',
                resetActions: 'actions/resetActions',
            }),
            save() {
                if(this.validator.passes() && !this.source) {
                    const payload = this.floorplan;
                    payload.customers_id = this.website.customers_id;
                    payload.communities_id = this.website.communities_id;
                    payload.websites_id = this.website.id;

                    payload.gallery.images.forEach( (value, key) => {
                        payload.gallery.images[key] = { url: this.getThumbnailUrlByFileId(value) };
                    } )

                    this.isProcessing = true;
                    this.$cmsDataProvider.create('createOrUpdateFloorplan', { websiteId: this.website.id, data: payload })
                    .then(() => {
                        this.$cmsDataProvider.get('floorplans', {websiteId: this.website.id}).then((response) => {
                            this.loadFloorplansInStore(response);
                        });
                        this.isProcessing = false;
                        this.notifySuccessBack('The floorplan was added successfully');
                    }).catch(() => {
                        this.isProcessing = false;
                        this.notifyError('There was an error saving the information');
                    });
                }else{
                    this.$router.push({ name: this.backModal.routeName });
                }
            },
            deletePlan() {
                EventBus.emit('close-confirmation');
            },
            cancelDelete() {
                EventBus.emit('close-confirmation');
            },
            showDeleteConfirmation(){
                EventBus.emit('show-confirmation');
            }           
        },
        validations: {
            'floorplan.common_name': 'required',
            'floorplan.unit_type': 'required',
            'floorplan.gallery.images': 'required',
            'floorplan.starting_price' : 'required | numeric | min:1',
            'floorplan.virtual_tour_url': 'when_present | url',
            'floorplan.square_feet': 'when_present | integer | min:0',
            'floorplan.bedrooms': 'when_present | integer | min:0',
            'floorplan.bathrooms': 'when_present | numeric | min:0',
            'floorplan.available_units': 'when_present | integer| min:0',
        },
        created() {
            this.initValidator();
            this.resetActions();
        },
        async mounted() {
            this.website = this.getWebsiteInfo

            if (!this.$route.params.FloorplanStatus){
                this.$cmsDataProvider.get('floorplan', { websiteId: this.website.id, floorplanName: this.$route.params.floorplanName })
                    .then( (response)=> {
                        this.floorplan = response;       
                        if(this.floorplan.source != null){
                            this.removeActionFromStore({title: 'Delete floor plan', routeName: 'websites.floorplans.show'})
                        }else{
                            this.resetActions();
                        }
                        this.source = response.source;
                        let galleryFileIds = [];
                        response.gallery.images.forEach( (value) => {
                            let fileId = ''
                            if(this.source)
                                fileId = value.thumbnail;
                            else
                                fileId = this.getFileIdByThumbnailUrl(value.thumbnail);
                            if(fileId)
                                galleryFileIds.push(fileId);
                        });
                        
                        this.floorplan.gallery.images = galleryFileIds;
                        this.floorplan.community_floorplan_name = this.floorplan.name;
                        this.setActiveModalTitle('edit "' + this.floorplan.name + '"');
                    })
                    .catch( error =>{
                        this.close(error);
                        this.notifyError(error.message);
                    })                  
            }else{
                this.close();
                this.notifyError('The Floorplan was deleted');                
            }
      
        },
        watch: {
            floorplan: {
                deep:true,
                immediate: true,
                handler() {
                    if (this.floorplan?.available_units < 0) this.floorplan.available_units = 0
                    if (this.floorplan?.bedrooms < 0) this.floorplan.bedrooms = 0
                    if (this.floorplan?.bathrooms < 0) this.floorplan.bathrooms = 0
                    if (this.floorplan?.square_feet < 0) this.floorplan.square_feet = 0
                }
            }
        }
    }
</script>

<style scoped>
.floorplan-photo .remove-photo {
    display: none;
}

.floorplan-photo:hover .remove-photo {
    display: block;
}

.form-input {
    @apply pl-10;
}
</style>

